import { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import graphQLClient from '@/lib/api/graphQLClient'
import CmsPage from '@/components/models/cms/page/CmsPage'
import tenantEntity from '@/entities/tenant/entity'
import { SINGLE_CMS_PAGE_QUERY } from '@/lib/gql'
import SeoHeader from '@/components/elements/seo/SeoHeader'

const Homepage: NextPage = ({ cmsPage, cmsConfig, travelTypes }: any) => {
  return (
    <>
      <SeoHeader seoSettings={cmsPage.seoSettings} noIndex={true} />
      <CmsPage cmsPage={cmsPage} cmsConfig={cmsConfig} travelTypes={travelTypes} />
    </>
  )
}

export async function getStaticProps({ locale }: any) {
  const tenant = await tenantEntity.repository.getObject(process.env.NEXT_PUBLIC_TENANT_ID, {
    withCms: true,
    withCmsErrorPage404: true,
  })
  const pageId = tenant.cms.errorPage404.id
  const data = await graphQLClient.request(SINGLE_CMS_PAGE_QUERY, { tenantId: process.env.NEXT_PUBLIC_TENANT_ID, id: pageId }).catch(() => {})
  if (!data) {
    return {
      props: {},
      notFound: true,
      revalidate: 10,
    }
  }

  return {
    props: {
      cmsPage: data.page,
      cmsConfig: data.tenant.cms.config,
      travelTypes: data.tenant.travelTypes,
      ...(await serverSideTranslations(locale, ['common', 'theme'])),
    },
    revalidate: 10,
  }
}

export default Homepage
